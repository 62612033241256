<template>
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 d-flex mb-3">
        <div class="custom-cards">
            <div class="c-cards-header pink-bg d-flex align-items-center">
                <div class="flex-grow-1">
                    <div class="icon-project text-center">
                        <img :src="logo" alt="" class="img-fluid svg-icon-02">
                    </div>
                </div>
                <div class="text-end">
                    <p class="text-white">{{ name }}</p>
                    <h1 class="text-white">{{ open }}</h1>
                </div>
            </div>
            <div class="c-cards-body pb-0">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="curved-card d-flex align-items-center mb-3">
                            <div class="flex-grow-1">
                                <a href=""><img :src="require('@/assets/img/icons-explorer.svg')" alt=""
                                                class="img-fluid svg-icon"></a>
                            </div>
                            <div class="text-end">
                                <h3 class="">Open</h3>
                                <h2 class="text-purple">{{ open }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="curved-card d-flex align-items-center mb-3">
                            <div class="flex-grow-1">
                                <img :src="require('@/assets/img/icon-complete.svg')" alt="" class="img-fluid svg-icon">
                            </div>
                            <div class="text-end">
                                <h3 class="">Complete</h3>
                                <h2 class="text-green">{{ complete }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Widget",
        props: {
            name: {
                type: String,
                required: true
            },
            logo: {
                type: String,
                required: true
            },
            open: {
                type: [Number, String],
                required: false,
                default: 0
            },
            complete: {
                type: [Number, String],
                required: false,
                default: 0
            },
        }
    }
</script>
